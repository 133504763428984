<template>
    <div>
        <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
        />
      
      <data-table
        :data-source="ordredServices"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-pack-service"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
      <modal
        title="Ajout de Servie Pack"
        id="add-pack-service"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="addingPackService"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-libelle">Libelle</label>
                    <input
                        type="text"
                        id="add-libelle"
                        name="add-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </modal>
      <modal
        title="Modification de Service Pack"
        id="update-pack-service"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editingPackService"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-libelle">Libelle</label>
                    <input
                        type="text"
                        id="edit-libelle"
                        name="edit-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
            
          </div>
          
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import Navbar from '../../../components/navbar.vue'
import { ADD_PACK_SERVICE, UPDATE_PACK_SERVICE } from '../../../graphql/pack'
  const Actions = () => import('../../../components/admin/pack/serviceActions.vue')
  export default {
      components: { Navbar, DataTable, Modal},
      data(){
          return {
            navbarItems: [
              {
                libelle: 'Pack Managment'
              },{
                libelle: 'Services'
              }
            ],
            pageIcon: 'la-gift',
            pageTitle: 'Service managment',
            pageDescription: 'Gestion des services d\'un pack d\'abonnement sur sur CASEAC',
              libelle: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.code = this.selectedObject.code
                    }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              
          },
          addingPackService(){
              let data = {
                  libelle: this.libelle,
              }
              this.$apollo.mutate({
                  mutation: ADD_PACK_SERVICE,
                  variables: {
                      "service": {
                          ...data
                      }
                  },
                  update: ({data}) => {
                      console.log(`Pack Service add successfully on uid ${data.addMarketTypeObject}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editingPackService(){
              let data = {
                  libelle: this.libelle,
              }
              this.$apollo.mutate({
                  mutation: UPDATE_PACK_SERVICE,
                  variables: {
                      "service": {
                          ...data
                      }, 
                      "uid": this.selectedObject.uid
                  },
                  update: () => {
                      console.log(`Pack Service ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              services: 'packs/services',
              selectedObject: 'selectedObject'
          }),
          ordredServices(){
            return [...this.services].sort((a,b) => a.libelle.localeCompare(b.libelle))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Code', name: 'code', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>